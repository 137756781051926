import {combineReducers} from 'redux'
/*DO NOT EDIT OR DELETE THIS LINE: YEOMAN ANCHOR IMPORT*/
import candidate from "../pages/candidate/redux/candidate.reducer.js"
import LoginReducer from '../pages/Login/redux/login.reducer'

export default combineReducers({
  // Reducers here
  login: LoginReducer,
  /*DO NOT EDIT OR DELETE THIS LINE: YEOMAN ANCHOR REDUCER*/
candidate,

})
