import * as types from "./login.types.js";

export function userLogged(user) {
    return {
        type: types.LOGGED,
        payload: user
    };
};

export function userNotLogged(error) {
    return {
        type: types.NOT_LOGGED,
        payload: error
    };
};

export function userLogout(history) {
    return {
        type: types.LOGOUT,
        payload: {
            history: history
        }
    };
};

export function tokenRefreshed(user,history) {
    return {
        type: types.REFRESHED_TOKEN,
        payload: {
            user,
            history: history
        }
    };
};