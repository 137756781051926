import {takeLatest, select} from 'redux-saga/effects';

function* exampleSaga(action) {
    console.log("USER LOGGED SAGA", "console log fired with redux-saga")
}


function* mySaga() {
    yield takeLatest('AUTH_LOGGED', exampleSaga);

}

export default mySaga;
