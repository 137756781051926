// @ts-nocheck
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  FormText,
  Row,
  Col,
  Card,
} from "reactstrap";
import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import store from "../../../store/store";
import { created, requestUpdate, updated } from "../redux/candidate.actions";
import GraphqlClient from "../../../client/graphql.client";
import { CSSTransition } from "react-transition-group";
import { TextInputHook } from "../../../hooks/text_input.hook";
import { NumberInputHook } from "../../../hooks/number_input.hook";
import { CheckBoxInputHook } from "../../../hooks/checkbox.hook";
import Datetime from "react-datetime";
import DateRangePickerWrapper from "../../../components/DateRangePicker/DateRangePickerWrapper";
import ClassNames from "classnames";
import it_IT from "./it_IT.json";
//import PdfViewer, { Worker, LocalizationMap } from "@phuocng/react-pdf-viewer";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import { FilePond, File, registerPlugin } from "react-filepond";
import * as FilePondObj from "filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import _ from "lodash";
import moment from "moment";
import Geosuggest from "react-geosuggest";

FilePondObj.registerPlugin(FilePondPluginFileEncode);

const PdfViewer = React.memo((props) => {
  const [fileContent, setFileContent] = useState(() => {
    if (props.dbinfo != "") {
      // ESISTE GIA UN FILE CARICATO  ho un base64 string devo creare bloburl

      const bytes = atob(props.dbinfo);

      let length = bytes.length;
      let out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      const blob = new Blob([out], { type: "application/pdf" });

      const dburlesistente = URL.createObjectURL(blob);

      return dburlesistente;
    } else {
      return "";
    }
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!props.newUrl || props.newUrl.length === 0) {
      console.log("Nessun nuovo dato");
      return;
    }

    setLoading(true);
    setFileContent(props.newUrl);
    setLoading(false);
  }, [props.newUrl]);

  if (loading) {
    return <div>Caricamento in corso...</div>;
  }

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <div
        style={{
          height: "750px",
          color: "black",
        }}
      >
        {fileContent.length > 0 && (
          <Viewer localization={it_IT} fileUrl={fileContent} />
        )}
      </div>
    </Worker>
  );
});

const CandidateUpdateComponent: FunctionComponent = (props: {
  data: Candidate;
  toggle: Function;
}) => {
  const candidate_firstName = TextInputHook(props.data.firstName);
  const candidate_lastName = TextInputHook(props.data.lastName);
  const candidate_email = TextInputHook(props.data.email);
  const [candidate_birthDate, setBirthDate] = useState(
    moment.unix(props.data.birthDate)
  );

  /*
  const candidate_residentialAddress = TextInputHook(
    props.data.residentialAddress
  );
  */

  const [candidate_residentialAddress, setResidentialAddress] = useState(
    props.data.residentialAddress
  );
  const [
    residentialAddressCoordinates,
    setResidentialAddressCoordinates,
  ] = useState();

  const residentialRef = useRef(null);

  const onSuggestResidentialAddress = (suggest) => {
    if (suggest && suggest.gmaps) {
      setResidentialAddressCoordinates(suggest.location);
      setResidentialAddress(suggest.gmaps.formatted_address);
    }
  };

  const candidate_mobileNumber = TextInputHook(props.data.mobileNumber);
  const candidate_presentation = TextInputHook(props.data.presentation);
  const candidate_driverLicensed = CheckBoxInputHook(props.data.driverLicensed);
  const candidate_workingParTime = CheckBoxInputHook(props.data.workingParTime);
  const candidate_workingFullTime = CheckBoxInputHook(
    props.data.workingFullTime
  );
  const candidate_maxDistanceToLocation = NumberInputHook(
    props.data.maxDistanceToLocation
  );
  const candidate_educationalBackground = TextInputHook(
    props.data.educationalBackground
  );

  const [submitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState([]);
  const [contenuto, setContenuto] = useState(props.data.base64Curriculum);
  const [blobUrl, setBlobUrl] = useState("");

  const [skills, setSkills] = useState(props.data.skills);

  const [workExperiences, setWorkExperiences] = useState(
    props.data.workExperiences
  );

  const [certifications, setCertifications] = useState(
    props.data.certifications
  );

  const base64toBlob = (data: string) => {
    //Cut the prefix `data:application/pdf;base64` from the raw base 64
    //const base64WithoutPrefix = data.substr(
    //  "data:application/pdf;base64,".length
    //);

    //***TAGLIO PREFISSO INUTILE PERCHE NELLA encode NON VIENE INSERITO QUINDI PASSO DIRETTAMENTE data***

    const bytes = atob(data);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: "application/pdf" });
  };

  const updateFile = async (files) => {
    setFiles(files);
    const content = await files[0].getFileEncodeBase64String();
    setContenuto(content);

    await createBlobUrl(content);
  };

  const createBlobUrl = async (content) => {
    if (!content || content.length == 0) return;
    const blob = base64toBlob(content);
    const url = URL.createObjectURL(blob);
    setBlobUrl(url);
  };

  const addEmptyWorkExperience = () => {
    let newIndex = 0;
    if (workExperiences.length > 0) {
      newIndex =
        _.maxBy(workExperiences, function (w) {
          return w.index;
        }).index + 1;
    }

    const newWorkExperience = {
      index: newIndex,
      fromDate: "",
      toDate: "",
      company: "",
      description: "",
    };

    setWorkExperiences([...workExperiences, newWorkExperience]);
  };

  const removeWorkExperience = (workExperienceToRemove) => {
    const workExperiencesList = workExperiences.filter(
      (workExperience) => workExperience.index != workExperienceToRemove.index
    );
    setWorkExperiences([...workExperiencesList]);
  };
  const updateWorkExperience = (updatedWorkExperience) => {
    let newWorkExperiences = [...workExperiences];
    const index = _.findIndex(workExperiences, {
      index: updatedWorkExperience.index,
    });
    newWorkExperiences.splice(index, 1, updatedWorkExperience);
    setWorkExperiences([...newWorkExperiences]);
  };

  const addEmptyCertification = () => {
    let newIndex = 0;
    if (certifications.length > 0) {
      newIndex =
        _.maxBy(certifications, function (w) {
          return w.index;
        }).index + 1;
    }

    const newCertification = {
      index: newIndex,
      fromDate: "",
      toDate: "",
      company: "",
      description: "",
    };

    setCertifications([...certifications, newCertification]);
  };
  const removeCertification = (certificationToRemove) => {
    const certificationsList = certifications.filter(
      (certification) => certification.index != certificationToRemove.index
    );
    setCertifications([...certificationsList]);
  };
  const updateCertification = (updatedCertification) => {
    let newCertifications = [...certifications];
    const index = _.findIndex(certifications, {
      index: updatedCertification.index,
    });
    newCertifications.splice(index, 1, updatedCertification);
    setCertifications([...newCertifications]);
  };

  const addEmptySkill = () => {
    let newIndex = 0;
    if (skills.length > 0) {
      newIndex =
        _.maxBy(skills, function (s) {
          return s.index;
        }).index + 1;
    }

    let newSkill = { index: newIndex, description: "", level: "" };
    setSkills([...skills, newSkill]);
  };

  const removeSkill = (skillToRemove) => {
    const skillsList = skills.filter(
      (skill) => skill.index != skillToRemove.index
    );
    setSkills([...skillsList]);
  };

  const updateSkill = (updatedSkill) => {
    let newSkills = [...skills];
    const index = _.findIndex(skills, { index: updatedSkill.index });
    newSkills.splice(index, 1, updatedSkill);
    setSkills([...newSkills]);
  };

  const salva = async () => {
    try {
      setLoading(true);

      const res = await GraphqlClient.updateCandidate({
        id: props.data.id,
        candidate: {
          firstName: candidate_firstName.getValue(),
          lastName: candidate_lastName.getValue(),
          email: candidate_email.getValue(),
          birthDate: moment(candidate_birthDate).unix(),
          base64Curriculum: contenuto,
          skills: skills,
          workExperiences: workExperiences,
          ceertifications: certifications,
          residentialAddress: candidate_residentialAddress,
          mobileNumber: candidate_mobileNumber.getValue(),
          driverLicensed: candidate_driverLicensed.getValue(),
          educationalBackground: candidate_educationalBackground.getValue(),
          maxDistanceToLocation: candidate_maxDistanceToLocation.getValue(),
          presentation: candidate_presentation.getValue(),
          workingFullTime: candidate_workingFullTime.getValue(),
          workingParTime: candidate_workingParTime.getValue(),
          residentialAddressLongitude: props.data.residentialAddressLongitude,
          residentialAddressLatitude: props.data.residentialAddressLatitude,
        },
      });
      store.dispatch(requestUpdate(res.updateCandidate)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
      props.toggle();
    } catch (e) {
      console.error(e);
      setSubmitError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (submitError) {
      const timer = setTimeout(() => {
        setSubmitError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [submitError]);

  //*******************************************
  //      PARTE AGGIUNTA

  const modificaData = (data) => {
    let newDate = moment.unix(data);
    if (data == 0) {
      // quando aggiungo una nuova workExperience o una certification data è ZERO e non ritorno nulla
      return;
    }
    return newDate;
  };

  //*******************************************

  return (
    <Modal
      modalClassName={ClassNames("modal-black", "extra-large-sparkle-modal")}
      isOpen={true}
      toggle={props.toggle}
    >
      <Row>
        <Col xs={12} md={12}>
          <div className="modal-header justify-content-center">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={props.toggle}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Modifica</h6>
          </div>
        </Col>

        <Col xs={12} md={6} style={{ paddingLeft: 50, paddingTop: 40 }}>
          <ModalBody>
            <FormGroup>
              <Label for="candidate_firstName">Nome</Label>
              <Input
                {...candidate_firstName.input}
                name="candidate_firstName"
                id="candidate_firstName"
                placeholder="Inserisci candidate_firstName"
              />
            </FormGroup>
            <FormGroup>
              <Label for="candidate_lastName">Cognome</Label>
              <Input
                {...candidate_lastName.input}
                name="candidate_lastName"
                id="candidate_lastName"
                placeholder="Inserisci candidate_lastName"
              />
            </FormGroup>
            <FormGroup>
              <Label for="candidate_email">E-Mail</Label>
              <Input
                {...candidate_email.input}
                name="candidate_email"
                id="candidate_email"
                placeholder="Inserisci candidate_email"
              />
            </FormGroup>
            <FormGroup>
              <Label for="candidate_birthDate">Data nascita</Label>
              <Datetime
                id="startDate"
                value={candidate_birthDate}
                closeOnSelect
                closeOnTab
                onChange={(dt) => {
                  setBirthDate(dt.toDate());
                }}
                timeFormat={false}
                inputProps={{ placeholder: "Data di inizio produzione" }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="candidate_presentation">Presentazione</Label>
              <Input
                {...candidate_presentation.input}
                name="candidate_presentation"
                id="candidate_presentation"
                placeholder="Inserisci breve presentazione"
              />
            </FormGroup>
            <FormGroup>
              <Input
                {...candidate_driverLicensed.input}
                name="candidate_driverLicensed"
                id="candidate_driverLicensed"
              />
              <Label for="candidate_driverLicensed">Automunito</Label>
            </FormGroup>
            <FormGroup>
              <Input
                {...candidate_workingParTime.input}
                name="candidate_workingParTime"
                id="candidate_workingParTime"
              />
              <Label check for="candidate_workingParTime">
                Disponibilità part-time
              </Label>
            </FormGroup>
            <FormGroup>
              <Input
                {...candidate_workingFullTime.input}
                name="candidate_workingFullTime"
                id="candidate_workingFullTime"
              />
              <Label for="candidate_workingFullTime">
                Disponibilità full-time
              </Label>
            </FormGroup>
            <FormGroup>
              <Label for="candidate_maxDistanceToLocation">
                Distanza massima
              </Label>
              <Input
                {...candidate_maxDistanceToLocation.input}
                name="candidate_maxDistanceToLocation"
                id="candidate_maxDistanceToLocation"
                placeholder="Inserisci distanza massima da residenza"
              />
            </FormGroup>
            <FormGroup>
              <Label for="candidate_educationalBackground">
                Titolo di studio
              </Label>
              <Input
                {...candidate_educationalBackground.input}
                name="candidate_educationalBackground"
                id="candidate_educationalBackground"
                placeholder="Inserisci titolo di studio"
              />
            </FormGroup>
            <FormGroup>
              <Label for="candidate_residentialAddress">
                Indirizzo residenza
              </Label>
              <Geosuggest
                ref={residentialRef}
                inputClassName={"form-control"}
                initialValue={candidate_residentialAddress}
                placeholder={"Inserisci indirizzo.."}
                onSuggestSelect={onSuggestResidentialAddress}
              />
            </FormGroup>

            <FormGroup>
              <Label for="candidate_mobileNumber">Cellulare</Label>
              <Input
                {...candidate_mobileNumber.input}
                name="candidate_mobileNumber"
                id="candidate_mobileNumber"
                placeholder="Inserisci candidate_mobileNumber"
              />
            </FormGroup>

            {skills?.map((skill) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FormGroup
                  className="extendFlex"
                  style={{ flex: 1 }}
                  q
                  label="Descrizione"
                >
                  <Input
                    name="fieldName"
                    type="text"
                    className="react-select primary"
                    classNamePrefix="react-select"
                    value={skill.description}
                    onChange={(e) => {
                      skill.description = e.target.value;
                      updateSkill(skill);
                    }}
                    placeholder="Inserire descrizione"
                  />
                </FormGroup>
                <div style={{ width: "5px" }} />
                <FormGroup
                  className="extendFlex"
                  style={{ flex: 1 }}
                  label="Valutazione"
                >
                  <Input
                    name="fieldName"
                    type="text"
                    className="react-select primary"
                    classNamePrefix="react-select"
                    value={skill.level}
                    onChange={(e) => {
                      skill.level = e.target.value;
                      updateSkill(skill);
                    }}
                    placeholder="Inserire livello"
                  />
                </FormGroup>
                <Button
                  className={"h-100 ml-3"}
                  color="danger"
                  size="sm"
                  onClick={() => removeSkill(skill)}
                >
                  <i className="tim-icons icon-simple-remove" />
                </Button>
              </div>
            ))}
            <button className="btn btn-primary w-100" onClick={addEmptySkill}>
              <i className="tim-icons icon-simple-add" />
              Aggiungi skill
            </button>

            {workExperiences?.map((workExperience) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <Card>
                  <Col xs={12} sm={12} lg={12}>
                    <DateRangePickerWrapper
                      disabled={false}
                      initialStartDate={modificaData(workExperience.fromDate)} // momentPropTypes.momentObj or null,
                      startDateId="startDate" // PropTypes.string.isRequired,
                      initialEndDate={modificaData(workExperience.toDate)} // momentPropTypes.momentObj or null,
                      endDateId="endDate" // PropTypes.string.isRequired,
                      small
                      enableOutsideDays={true}
                      startDatePlaceholderText={"Data inizio"}
                      endDatePlaceholderText={"Data fine"}
                      showDefaultInputIcon
                      isOutsideRange={() => false}
                      onChange={(fromDate, toDate) => {
                        workExperience.fromDate = fromDate.unix();
                        workExperience.toDate = toDate.unix();
                        updateWorkExperience(workExperience);
                      }}
                    />
                  </Col>

                  <FormGroup
                    className="extendFlex"
                    style={{ flex: 1 }}
                    q
                    label="Azienda"
                  >
                    <Input
                      name="company"
                      type="text"
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={workExperience.company}
                      onChange={(e) => {
                        workExperience.company = e.target.value;
                        updateWorkExperience(workExperience);
                      }}
                      placeholder="Nome azienda"
                    />
                  </FormGroup>
                  <div style={{ width: "5px" }} />
                  <FormGroup
                    className="extendFlex"
                    style={{ flex: 1 }}
                    q
                    label="Al"
                  >
                    <Input
                      name="description"
                      type="text"
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={workExperience.description}
                      onChange={(e) => {
                        workExperience.description = e.target.value;
                        updateWorkExperience(workExperience);
                      }}
                      placeholder="Breve descrizione esperienza"
                    />
                  </FormGroup>
                  <Button
                    className={"h-100 ml-3"}
                    color="danger"
                    size="sm"
                    onClick={() => removeWorkExperience(workExperience)}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </Button>
                </Card>
              </div>
            ))}

            <button
              className="btn btn-primary w-100"
              onClick={addEmptyWorkExperience}
            >
              <i className="tim-icons icon-simple-add" />
              Aggiungi esperienza lavorativa
            </button>

            {certifications?.map((certification) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <Card>
                  <Col xs={10} sm={12} lg={12}>
                    <DateRangePickerWrapper
                      disabled={false}
                      initialStartDate={modificaData(certification.fromDate)} // momentPropTypes.momentObj or null,
                      startDateId="startDate" // PropTypes.string.isRequired,
                      initialEndDate={modificaData(certification.toDate)} // momentPropTypes.momentObj or null,
                      endDateId="endDate" // PropTypes.string.isRequired,
                      small
                      enableOutsideDays={true}
                      startDatePlaceholderText={"Data inizio"}
                      endDatePlaceholderText={"Data fine"}
                      showDefaultInputIcon
                      isOutsideRange={() => false}
                      onChange={(fromDate, toDate) => {
                        certification.fromDate = fromDate.unix();
                        certification.toDate = toDate.unix();
                        updateCertification(certification);
                      }}
                    />
                  </Col>

                  <FormGroup
                    className="extendFlex"
                    style={{ flex: 1 }}
                    q
                    label="Azienda"
                  >
                    <Input
                      name="company"
                      type="text"
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={certification.company}
                      onChange={(e) => {
                        certification.company = e.target.value;
                        updateCertification(certification);
                      }}
                      placeholder="Ente emittente"
                    />
                  </FormGroup>
                  <div style={{ width: "5px" }} />
                  <FormGroup
                    className="extendFlex"
                    style={{ flex: 1 }}
                    q
                    label="Al"
                  >
                    <Input
                      name="description"
                      type="text"
                      className="react-select primary"
                      classNamePrefix="react-select"
                      value={certification.description}
                      onChange={(e) => {
                        certification.description = e.target.value;
                        updateCertification(certification);
                      }}
                      placeholder="Descrizione certificazione"
                    />
                  </FormGroup>
                  <Button
                    className={"h-100 ml-3"}
                    color="danger"
                    size="sm"
                    onClick={() => removeCertification(certification)}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </Button>
                </Card>
              </div>
            ))}

            <button
              className="btn btn-primary w-100"
              onClick={addEmptyCertification}
            >
              <i className="tim-icons icon-simple-add" />
              Aggiungi certificazione
            </button>

            <FormGroup>
              <FilePond
                files={files}
                onupdatefiles={updateFile}
                allowMultiple={false}
                name="files"
                labelIdle='Drag & Drop i tuoi  files o <span class="filepond--label-action">Sfoglia</span>'
              />
            </FormGroup>
            <CSSTransition
              in={submitError}
              appear={true}
              unmountOnExit
              classNames="error"
              timeout={300}
            >
              <blockquote className="blockquote blockquote-danger">
                <p className="mb-0 text-danger">
                  Errore durante il salvataggio.
                </p>
              </blockquote>
            </CSSTransition>
          </ModalBody>
        </Col>
        <Col xs={12} md={6} style={{ paddingLeft: 50, paddingTop: 40 }}>
          {<PdfViewer newUrl={blobUrl} dbinfo={contenuto} />}
        </Col>
      </Row>

      <ModalFooter>
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={props.toggle}
        >
          Annulla
        </Button>
        <Button color="primary" type="button" onClick={salva}>
          {loading ? <i className="fa fa-spin fa-spinner" /> : "Salva"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CandidateUpdateComponent;
