// @ts-nocheck

import "./App.css";
import React, { Component } from "react";
import store, { persistor } from "./store/store.js";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import AdminLayout from "./layouts/Admin/Admin";
import "./assets/css/nucleo-icons.css";
import "./assets/css/sparkle.css";
import "./assets/scss/black-dashboard-pro-react.scss?v=1.0.0";
import "./assets/demo/demo.css";
import AuthLayout from "./layouts/Auth/Auth";
import GraphqlClient from "./client/graphql.client";
import { tokenRefreshed, userLogout } from "./pages/Login/redux/login.actions";

class App extends Component {
  state = {
    loading: false,
  };

  onBeforeLift = async () => {
    // On before lift wait for redux-rehydrate and next fires

    // Check if logged
    let user = store.getState().login;

    if (!user.logged) {
      return;
    }

    if (!user.refreshedToken) {
      try {
        const res = await GraphqlClient.getNewAccessToken({ refreshToken: user.token });
        store.dispatch(tokenRefreshed(res.getNewAccessToken));
      } catch (e) {
        store.dispatch(userLogout());
      } finally {
        this.setState({ loading: false });
      }
    }

    return;
  };

  render() {
    if (this.state.loading) {
      return (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Caricamento in corso..
        </div>
      );
    }

    return (
      <Provider store={store}>
        <PersistGate
          onBeforeLift={this.onBeforeLift}
          loading={null}
          persistor={persistor}
        >
          <BrowserRouter>
            <Switch>
              <NonAuthenticatedRoutes path={"/auth"} />
              <AuthenticatedRoutes path={"/admin"} />

              <Redirect from="/" to="/admin/dashboard" />
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}

function AuthenticatedRoutes({ children, ...rest }) {
  console.log("REST", rest);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        const isAuthenticated = store.getState().login.logged;
        if (isAuthenticated) {
          // @ts-ignore
          return <AdminLayout {...rest}>children</AdminLayout>;
        }
        return (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

function NonAuthenticatedRoutes({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => <AuthLayout {...rest}>children</AuthLayout>}
    />
  );
}

export default App;
