import store from "../../store/store";

export const OneOfRole = requiredRoles => {
    if (!requiredRoles) return true;
    if (!store.getState().login.logged) return false;
    const user = store.getState().login.user;
    const userRoles = [user.role];

    return requiredRoles.some(r => userRoles.includes(r));
};

export const BeautifyRole = role => {
    /*
    * Funzione che serve a convertire un ruolo in un testo più user friendly.
    * Esempio: admin diventa "Amministratore"
    */

    switch (role) {
        case "admin":
            return "Amministratore";
        default:
            return "-";
    }
}
