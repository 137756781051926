import type { GraphQLClient } from 'graphql-request';
import type * as Dom from 'graphql-request/dist/types.dom';
import { print } from 'graphql';
import gql from 'graphql-tag';
type Maybe<T> = T | null;
type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Json: any;
  Time: any;
  UnixDate: any;
  Upload: any;
};






type Candidate = {
  __typename?: 'Candidate';
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  birthDate: Scalars['Int'];
  base64Curriculum: Scalars['String'];
  skills: Array<Skill>;
  workExperiences: Array<WorkExperience>;
  certifications: Array<Certification>;
  residentialAddress: Scalars['String'];
  mobileNumber: Scalars['String'];
  driverLicensed: Scalars['Boolean'];
  workingParTime: Scalars['Boolean'];
  workingFullTime: Scalars['Boolean'];
  maxDistanceToLocation: Scalars['Int'];
  educationalBackground: Scalars['String'];
  presentation: Scalars['String'];
  residentialAddressLatitude: Scalars['Float'];
  residentialAddressLongitude: Scalars['Float'];
};

type Certification = {
  __typename?: 'Certification';
  index: Scalars['Int'];
  fromDate: Scalars['Int'];
  toDate: Scalars['Int'];
  company: Scalars['String'];
  description: Scalars['String'];
};

type InputCandidate = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  birthDate: Scalars['Int'];
  base64Curriculum: Scalars['String'];
  skills: Array<InputSkill>;
  workExperiences: Array<InputWorkExperience>;
  ceertifications: Array<InputCertification>;
  residentialAddress: Scalars['String'];
  mobileNumber: Scalars['String'];
  driverLicensed: Scalars['Boolean'];
  workingParTime: Scalars['Boolean'];
  workingFullTime: Scalars['Boolean'];
  maxDistanceToLocation: Scalars['Int'];
  educationalBackground: Scalars['String'];
  presentation: Scalars['String'];
  residentialAddressLatitude: Scalars['Float'];
  residentialAddressLongitude: Scalars['Float'];
};

type InputCertification = {
  index: Scalars['Int'];
  fromDate: Scalars['Int'];
  toDate: Scalars['Int'];
  company: Scalars['String'];
  description: Scalars['String'];
};

type InputSkill = {
  index: Scalars['Int'];
  description: Scalars['String'];
  level: Scalars['String'];
};

type InputUtente = {
  nome: Scalars['String'];
  cognome: Scalars['String'];
  password: Scalars['String'];
  confermaPassword: Scalars['String'];
  salt: Scalars['String'];
  email: Scalars['String'];
  avatar: Scalars['String'];
  dataNascita: Scalars['Int'];
  provider: Scalars['String'];
};

type InputUtenteConPassword = {
  nome: Scalars['String'];
  cognome: Scalars['String'];
  password: Scalars['String'];
  confermaPassword: Scalars['String'];
  email: Scalars['String'];
};

type InputWorkExperience = {
  index: Scalars['Int'];
  fromDate: Scalars['Int'];
  toDate: Scalars['Int'];
  company: Scalars['String'];
  description: Scalars['String'];
};


type Mutation = {
  __typename?: 'Mutation';
  pingMutation: Scalars['String'];
  createCandidate: Candidate;
  updateCandidate: Candidate;
  deleteCandidate: ResponseDelete;
  creaUtente: TokenDetails;
};


type MutationCreateCandidateArgs = {
  candidate: InputCandidate;
};


type MutationUpdateCandidateArgs = {
  id: Scalars['String'];
  candidate: InputCandidate;
};


type MutationDeleteCandidateArgs = {
  id: Scalars['String'];
};


type MutationCreaUtenteArgs = {
  utente?: Maybe<InputUtenteConPassword>;
};

type Query = {
  __typename?: 'Query';
  ping: Scalars['String'];
  getCandidates: Array<Candidate>;
  getCandidateById: Candidate;
  utenti?: Maybe<Array<Maybe<Utente>>>;
  utenteLoggato: Utente;
  login: TokenDetails;
  basicLogin: TokenDetails;
  getNewAccessToken: TokenDetails;
};


type QueryGetCandidateByIdArgs = {
  id: Scalars['String'];
};


type QueryLoginArgs = {
  identityToken: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


type QueryBasicLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


type QueryGetNewAccessTokenArgs = {
  refreshToken: Scalars['String'];
};

type ResponseDelete = {
  __typename?: 'ResponseDelete';
  success: Scalars['Boolean'];
  message: Scalars['String'];
  rowAffected: Scalars['Int'];
};

enum Role {
  Amministratore = 'AMMINISTRATORE',
  Utente = 'UTENTE'
}

type Skill = {
  __typename?: 'Skill';
  index: Scalars['Int'];
  description: Scalars['String'];
  level: Scalars['String'];
};


type TokenDetails = {
  __typename?: 'TokenDetails';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
  accessUuid: Scalars['String'];
  refreshUuid: Scalars['String'];
  atExpires: Scalars['Int'];
  rtExpires: Scalars['Int'];
  utente: Utente;
};



type Utente = {
  __typename?: 'Utente';
  nome: Scalars['String'];
  cognome: Scalars['String'];
  email: Scalars['String'];
  ruolo: Scalars['String'];
  avatar: Scalars['String'];
  dataNascita: Scalars['Int'];
  provider: Scalars['String'];
  dataRegistrazione: Scalars['Int'];
  createdAt?: Maybe<Scalars['Time']>;
};

type WorkExperience = {
  __typename?: 'WorkExperience';
  index: Scalars['Int'];
  fromDate: Scalars['Int'];
  toDate: Scalars['Int'];
  company: Scalars['String'];
  description: Scalars['String'];
};

type CreaUtenteMutationVariables = Exact<{
  utente?: Maybe<InputUtenteConPassword>;
}>;


type CreaUtenteMutation = (
  { __typename?: 'Mutation' }
  & { creaUtente: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires'>
    & { utente: (
      { __typename?: 'Utente' }
      & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt'>
    ) }
  ) }
);

type CreateCandidateMutationVariables = Exact<{
  candidate: InputCandidate;
}>;


type CreateCandidateMutation = (
  { __typename?: 'Mutation' }
  & { createCandidate: (
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id' | 'firstName' | 'lastName' | 'email' | 'birthDate' | 'base64Curriculum' | 'residentialAddress' | 'mobileNumber' | 'driverLicensed' | 'workingParTime' | 'workingFullTime' | 'maxDistanceToLocation' | 'educationalBackground' | 'presentation' | 'residentialAddressLatitude' | 'residentialAddressLongitude'>
    & { skills: Array<(
      { __typename?: 'Skill' }
      & Pick<Skill, 'index' | 'description' | 'level'>
    )>, workExperiences: Array<(
      { __typename?: 'WorkExperience' }
      & Pick<WorkExperience, 'index' | 'fromDate' | 'toDate' | 'company' | 'description'>
    )>, certifications: Array<(
      { __typename?: 'Certification' }
      & Pick<Certification, 'index' | 'fromDate' | 'toDate' | 'company' | 'description'>
    )> }
  ) }
);

type DeleteCandidateMutationVariables = Exact<{
  id: Scalars['String'];
}>;


type DeleteCandidateMutation = (
  { __typename?: 'Mutation' }
  & { deleteCandidate: (
    { __typename?: 'ResponseDelete' }
    & Pick<ResponseDelete, 'success' | 'message' | 'rowAffected'>
  ) }
);

type PingMutationMutationVariables = Exact<{ [key: string]: never; }>;


type PingMutationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'pingMutation'>
);

type UpdateCandidateMutationVariables = Exact<{
  id: Scalars['String'];
  candidate: InputCandidate;
}>;


type UpdateCandidateMutation = (
  { __typename?: 'Mutation' }
  & { updateCandidate: (
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id' | 'firstName' | 'lastName' | 'email' | 'birthDate' | 'base64Curriculum' | 'residentialAddress' | 'mobileNumber' | 'driverLicensed' | 'workingParTime' | 'workingFullTime' | 'maxDistanceToLocation' | 'educationalBackground' | 'presentation' | 'residentialAddressLatitude' | 'residentialAddressLongitude'>
    & { skills: Array<(
      { __typename?: 'Skill' }
      & Pick<Skill, 'index' | 'description' | 'level'>
    )>, workExperiences: Array<(
      { __typename?: 'WorkExperience' }
      & Pick<WorkExperience, 'index' | 'fromDate' | 'toDate' | 'company' | 'description'>
    )>, certifications: Array<(
      { __typename?: 'Certification' }
      & Pick<Certification, 'index' | 'fromDate' | 'toDate' | 'company' | 'description'>
    )> }
  ) }
);

type BasicLoginQueryVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


type BasicLoginQuery = (
  { __typename?: 'Query' }
  & { basicLogin: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires'>
    & { utente: (
      { __typename?: 'Utente' }
      & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt'>
    ) }
  ) }
);

type GetCandidateByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


type GetCandidateByIdQuery = (
  { __typename?: 'Query' }
  & { getCandidateById: (
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id' | 'firstName' | 'lastName' | 'email' | 'birthDate' | 'base64Curriculum' | 'residentialAddress' | 'mobileNumber' | 'driverLicensed' | 'workingParTime' | 'workingFullTime' | 'maxDistanceToLocation' | 'educationalBackground' | 'presentation' | 'residentialAddressLatitude' | 'residentialAddressLongitude'>
    & { skills: Array<(
      { __typename?: 'Skill' }
      & Pick<Skill, 'index' | 'description' | 'level'>
    )>, workExperiences: Array<(
      { __typename?: 'WorkExperience' }
      & Pick<WorkExperience, 'index' | 'fromDate' | 'toDate' | 'company' | 'description'>
    )>, certifications: Array<(
      { __typename?: 'Certification' }
      & Pick<Certification, 'index' | 'fromDate' | 'toDate' | 'company' | 'description'>
    )> }
  ) }
);

type GetCandidatesQueryVariables = Exact<{ [key: string]: never; }>;


type GetCandidatesQuery = (
  { __typename?: 'Query' }
  & { getCandidates: Array<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id' | 'firstName' | 'lastName' | 'email' | 'birthDate' | 'base64Curriculum' | 'residentialAddress' | 'mobileNumber' | 'driverLicensed' | 'workingParTime' | 'workingFullTime' | 'maxDistanceToLocation' | 'educationalBackground' | 'presentation' | 'residentialAddressLatitude' | 'residentialAddressLongitude'>
    & { skills: Array<(
      { __typename?: 'Skill' }
      & Pick<Skill, 'index' | 'description' | 'level'>
    )>, workExperiences: Array<(
      { __typename?: 'WorkExperience' }
      & Pick<WorkExperience, 'index' | 'fromDate' | 'toDate' | 'company' | 'description'>
    )>, certifications: Array<(
      { __typename?: 'Certification' }
      & Pick<Certification, 'index' | 'fromDate' | 'toDate' | 'company' | 'description'>
    )> }
  )> }
);

type GetNewAccessTokenQueryVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


type GetNewAccessTokenQuery = (
  { __typename?: 'Query' }
  & { getNewAccessToken: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires'>
    & { utente: (
      { __typename?: 'Utente' }
      & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt'>
    ) }
  ) }
);

type LoginQueryVariables = Exact<{
  identityToken: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;


type LoginQuery = (
  { __typename?: 'Query' }
  & { login: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires'>
    & { utente: (
      { __typename?: 'Utente' }
      & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt'>
    ) }
  ) }
);

type PingQueryVariables = Exact<{ [key: string]: never; }>;


type PingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ping'>
);

type UtenteLoggatoQueryVariables = Exact<{ [key: string]: never; }>;


type UtenteLoggatoQuery = (
  { __typename?: 'Query' }
  & { utenteLoggato: (
    { __typename?: 'Utente' }
    & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt'>
  ) }
);

type UtentiQueryVariables = Exact<{ [key: string]: never; }>;


type UtentiQuery = (
  { __typename?: 'Query' }
  & { utenti?: Maybe<Array<Maybe<(
    { __typename?: 'Utente' }
    & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt'>
  )>>> }
);


 const CreaUtenteDocument = gql`
    mutation creaUtente($utente: InputUtenteConPassword) {
  creaUtente(utente: $utente) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    utente {
      nome
      cognome
      email
      ruolo
      avatar
      dataNascita
      provider
      dataRegistrazione
      createdAt
    }
  }
}
    `;
 const CreateCandidateDocument = gql`
    mutation createCandidate($candidate: InputCandidate!) {
  createCandidate(candidate: $candidate) {
    id
    firstName
    lastName
    email
    birthDate
    base64Curriculum
    skills {
      index
      description
      level
    }
    workExperiences {
      index
      fromDate
      toDate
      company
      description
    }
    certifications {
      index
      fromDate
      toDate
      company
      description
    }
    residentialAddress
    mobileNumber
    driverLicensed
    workingParTime
    workingFullTime
    maxDistanceToLocation
    educationalBackground
    presentation
    residentialAddressLatitude
    residentialAddressLongitude
  }
}
    `;
 const DeleteCandidateDocument = gql`
    mutation deleteCandidate($id: String!) {
  deleteCandidate(id: $id) {
    success
    message
    rowAffected
  }
}
    `;
 const PingMutationDocument = gql`
    mutation pingMutation {
  pingMutation
}
    `;
 const UpdateCandidateDocument = gql`
    mutation updateCandidate($id: String!, $candidate: InputCandidate!) {
  updateCandidate(id: $id, candidate: $candidate) {
    id
    firstName
    lastName
    email
    birthDate
    base64Curriculum
    skills {
      index
      description
      level
    }
    workExperiences {
      index
      fromDate
      toDate
      company
      description
    }
    certifications {
      index
      fromDate
      toDate
      company
      description
    }
    residentialAddress
    mobileNumber
    driverLicensed
    workingParTime
    workingFullTime
    maxDistanceToLocation
    educationalBackground
    presentation
    residentialAddressLatitude
    residentialAddressLongitude
  }
}
    `;
 const BasicLoginDocument = gql`
    query basicLogin($username: String!, $password: String!) {
  basicLogin(username: $username, password: $password) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    utente {
      nome
      cognome
      email
      ruolo
      avatar
      dataNascita
      provider
      dataRegistrazione
      createdAt
    }
  }
}
    `;
 const GetCandidateByIdDocument = gql`
    query getCandidateById($id: String!) {
  getCandidateById(id: $id) {
    id
    firstName
    lastName
    email
    birthDate
    base64Curriculum
    skills {
      index
      description
      level
    }
    workExperiences {
      index
      fromDate
      toDate
      company
      description
    }
    certifications {
      index
      fromDate
      toDate
      company
      description
    }
    residentialAddress
    mobileNumber
    driverLicensed
    workingParTime
    workingFullTime
    maxDistanceToLocation
    educationalBackground
    presentation
    residentialAddressLatitude
    residentialAddressLongitude
  }
}
    `;
 const GetCandidatesDocument = gql`
    query getCandidates {
  getCandidates {
    id
    firstName
    lastName
    email
    birthDate
    base64Curriculum
    skills {
      index
      description
      level
    }
    workExperiences {
      index
      fromDate
      toDate
      company
      description
    }
    certifications {
      index
      fromDate
      toDate
      company
      description
    }
    residentialAddress
    mobileNumber
    driverLicensed
    workingParTime
    workingFullTime
    maxDistanceToLocation
    educationalBackground
    presentation
    residentialAddressLatitude
    residentialAddressLongitude
  }
}
    `;
 const GetNewAccessTokenDocument = gql`
    query getNewAccessToken($refreshToken: String!) {
  getNewAccessToken(refreshToken: $refreshToken) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    utente {
      nome
      cognome
      email
      ruolo
      avatar
      dataNascita
      provider
      dataRegistrazione
      createdAt
    }
  }
}
    `;
 const LoginDocument = gql`
    query login($identityToken: String!, $firstName: String!, $lastName: String!) {
  login(identityToken: $identityToken, firstName: $firstName, lastName: $lastName) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    utente {
      nome
      cognome
      email
      ruolo
      avatar
      dataNascita
      provider
      dataRegistrazione
      createdAt
    }
  }
}
    `;
 const PingDocument = gql`
    query ping {
  ping
}
    `;
 const UtenteLoggatoDocument = gql`
    query utenteLoggato {
  utenteLoggato {
    nome
    cognome
    email
    ruolo
    avatar
    dataNascita
    provider
    dataRegistrazione
    createdAt
  }
}
    `;
 const UtentiDocument = gql`
    query utenti {
  utenti {
    nome
    cognome
    email
    ruolo
    avatar
    dataNascita
    provider
    dataRegistrazione
    createdAt
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    creaUtente(variables?: CreaUtenteMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreaUtenteMutation> {
      return withWrapper(() => client.request<CreaUtenteMutation>(print(CreaUtenteDocument), variables, requestHeaders));
    },
    createCandidate(variables: CreateCandidateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateCandidateMutation> {
      return withWrapper(() => client.request<CreateCandidateMutation>(print(CreateCandidateDocument), variables, requestHeaders));
    },
    deleteCandidate(variables: DeleteCandidateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteCandidateMutation> {
      return withWrapper(() => client.request<DeleteCandidateMutation>(print(DeleteCandidateDocument), variables, requestHeaders));
    },
    pingMutation(variables?: PingMutationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PingMutationMutation> {
      return withWrapper(() => client.request<PingMutationMutation>(print(PingMutationDocument), variables, requestHeaders));
    },
    updateCandidate(variables: UpdateCandidateMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateCandidateMutation> {
      return withWrapper(() => client.request<UpdateCandidateMutation>(print(UpdateCandidateDocument), variables, requestHeaders));
    },
    basicLogin(variables: BasicLoginQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BasicLoginQuery> {
      return withWrapper(() => client.request<BasicLoginQuery>(print(BasicLoginDocument), variables, requestHeaders));
    },
    getCandidateById(variables: GetCandidateByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCandidateByIdQuery> {
      return withWrapper(() => client.request<GetCandidateByIdQuery>(print(GetCandidateByIdDocument), variables, requestHeaders));
    },
    getCandidates(variables?: GetCandidatesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCandidatesQuery> {
      return withWrapper(() => client.request<GetCandidatesQuery>(print(GetCandidatesDocument), variables, requestHeaders));
    },
    getNewAccessToken(variables: GetNewAccessTokenQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetNewAccessTokenQuery> {
      return withWrapper(() => client.request<GetNewAccessTokenQuery>(print(GetNewAccessTokenDocument), variables, requestHeaders));
    },
    login(variables: LoginQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LoginQuery> {
      return withWrapper(() => client.request<LoginQuery>(print(LoginDocument), variables, requestHeaders));
    },
    ping(variables?: PingQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PingQuery> {
      return withWrapper(() => client.request<PingQuery>(print(PingDocument), variables, requestHeaders));
    },
    utenteLoggato(variables?: UtenteLoggatoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UtenteLoggatoQuery> {
      return withWrapper(() => client.request<UtenteLoggatoQuery>(print(UtenteLoggatoDocument), variables, requestHeaders));
    },
    utenti(variables?: UtentiQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UtentiQuery> {
      return withWrapper(() => client.request<UtentiQuery>(print(UtentiDocument), variables, requestHeaders));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;