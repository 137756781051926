import * as types from "./login.types.js";

// ONLY FOR OFFLINE TEST
const initialState = {
    logged: false,
    refreshedToken: false,
    logging: false,
    token: "",
    user: {}
};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case "persist/REHYDRATE":
            let loginRehydrated = {};
            if (action.payload && action.payload.login) loginRehydrated = action.payload.login;

            state = {
                ...loginRehydrated,
                refreshedToken: false,

            };
            break;
            case types.REFRESHED_TOKEN:
                state = {
                    ...state,
                    logged: true,
                    refreshedToken: true,
                    logging: false,
                    jwt: action.payload.jwt,
    
                    // ****** non aggiorno il campo user dello stete 
                    // ipotizzando che usi i dati salvati in cache ********
                    
                    //user: action.payload
                };
                break
            case types.LOGGED:
                state = {
                    ...state,
                    logged: true,
                    logging: false,
                    //jwt: action.payload.jwt,
    
                    // ****** aggiorno il campo token dello state invece che
                    //  aggiungere un campo jwt allo state  ********
    
                    token: action.payload.jwt,
                    user: action.payload,
                };
            break;
        case types.NOT_LOGGED:
            state = initialState;
            break;
        case types.LOGOUT:
            state = initialState;
            break;
        default:
            return state;
    }

    return state;
};

export default loginReducer;
