import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Input,
  Col,
  Button,
  UncontrolledTooltip,
  CardSubtitle,
} from "reactstrap";
import ReactTable from "react-table";
import classNames from "classnames";
import { connect } from "react-redux";
import { get } from "lodash";
import "./redux/candidate.reducer";
import CandidateCreateComponent from "./components/candidate.create.component";
import CandidateUpdateComponent from "./components/candidate.update.component";
import Fade from "react-reveal/Fade";
import ReactBSAlert from "react-bootstrap-sweetalert";
import _ from "lodash";
import {
  readedAll,
  requestDelete,
  deleted,
  deleteError,
} from "./redux/candidate.actions";
import GraphqlClient from "../../client/graphql.client";
import store from "../../store/store";
import moment from "moment";
import { matchSorter } from 'match-sorter'



class candidateComponent extends Component {
  state = {
    data: [],
    loading: false,
    showCreateModal: false,
    showUpdateModal: false,
    selectedRecordForUpdate: {},
    showDeleteAlert: false,
    selectedRecordForDelete: {},
  };


  caricaDati = async () => {
    try {
      const response = await GraphqlClient.getCandidates();
      store.dispatch(readedAll(response.getCandidates)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
    } catch (e) {
      console.log("Impossibile caricare i dati", e);
    }
  };

  componentDidMount() {
    this.caricaDati();
  }

  toggleShowCreateModal = () => {
    this.setState({
      showCreateModal: !this.state.showCreateModal,
    });
  };

  toggleShowUpdateModal = (record) => {
    this.setState({
      showUpdateModal: !this.state.showUpdateModal,
      selectedRecordForUpdate: record,
    });
    this.caricaDati();
  };

  elimina = async () => {
    try {
      this.toggleDeleteAlert(this.state.selectedRecordForDelete);
      store.dispatch(requestDelete(this.state.selectedRecordForDelete.id));
      await GraphqlClient.deleteCandidate({
        id: this.state.selectedRecordForDelete.id,
      });
      store.dispatch(deleted({ id: this.state.selectedRecordForDelete.id })); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
    } catch (e) {
      console.log("Impossibile eliminare il record", e);
      store.dispatch(deleteError(this.state.selectedRecordForDelete.id));
    }
  };

  toggleDeleteAlert = (record) =>
    this.setState({
      showDeleteAlert: !this.state.showDeleteAlert,
      selectedRecordForDelete: record,
    });

  actions = (cellInfo) => (
    <div className="actions-right">
      {get(cellInfo, "original._deleting", false) ? (
        <i className="fa fa-spin fa-spinner" />
      ) : null}
      <Button
        color="warning"
        size="sm"
        onClick={() => this.toggleShowUpdateModal(cellInfo.original)}
        className={classNames("btn-icon btn-link like")}
      >
        <i className="tim-icons icon-pencil" />
      </Button>{" "}
      <Button
        color="danger"
        size="sm"
        onClick={() => this.toggleDeleteAlert(cellInfo.original)}
        className={classNames("btn-icon btn-link like")}
      >
        <i className="tim-icons icon-simple-remove" />
      </Button>{" "}
    </div>
  );

  render() {
    return (
      <>
        {this.state.showDeleteAlert ? (
          <ReactBSAlert
            warning
            style={{ display: "block" }}
            title="Confermare l'eliminazione ?"
            onConfirm={() => this.elimina()}
            onCancel={() => this.toggleDeleteAlert()}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText="Si"
            cancelBtnText="No"
            showCancel
            btnSize=""
          >
            Stai per eliminare in modo definitivo:{" "}
            {_.get(this.state, "selectedRecordForDelete.lastName", "")}
          </ReactBSAlert>
        ) : null}
        {this.state.showCreateModal && (
          <CandidateCreateComponent
            show={this.state.showCreateModal}
            toggle={this.toggleShowCreateModal}
          />
        )}

        {this.state.showUpdateModal ? (
          <CandidateUpdateComponent
            data={this.state.selectedRecordForUpdate}
            toggle={this.toggleShowUpdateModal}
          />
        ) : null}

        {/*Contenuto pagina*/}
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  {/*Filtri*/}
                  <Row>
                    <Col className="mr-auto">
                      <button
                        className="btn btn-primary "
                        id="nuovoModal"
                        onClick={() => this.toggleShowCreateModal()}
                      >
                        <i className="tim-icons icon-simple-add" />
                        Nuovo
                      </button>
                      <UncontrolledTooltip
                        placement="bottom"
                        target="nuovoModal"
                        delay={0}
                      >
                        Crea un nuovo lotto
                        <br /> ( Hotkey F11)
                      </UncontrolledTooltip>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <button
                        id="refresh"
                        onClick={() => {
                          this.caricaDati();
                        }}
                        className="btn btn-primary btn-fab btn-icon "
                      >
                        <i className="tim-icons icon-refresh-01"></i>
                      </button>
                      <UncontrolledTooltip
                        placement="bottom"
                        target="refresh"
                        delay={0}
                      >
                        Ricarica i dati
                      </UncontrolledTooltip>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Lista</CardTitle>
                  <Fade when={this.props.loading}>
                    <CardSubtitle>
                      Caricamento in corso{" "}
                      <i className="fa fa-spin fa-spinner" />
                    </CardSubtitle>
                  </Fade>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.props.data}
                    filterable
                    resizable={true}
                    columns={[
                      {
                        Header: "Nome", accessor: "firstName", id: 'nome',
                        filterMethod: (filter, row) =>
                          matchSorter(row, filter.value, { keys: ["nome"] }),
                        filterAll: true
                      },
                      {
                        Header: "Cognome", accessor: "lastName", id: 'cognome',
                        filterMethod: (filter, row) =>
                          matchSorter(row, filter.value, { keys: ["cognome"] }),
                        filterAll: true
                      },
                      {
                        Header: "E-Mail", accessor: "email", id: "email",
                        filterMethod: (filter, row) =>
                          matchSorter(row, filter.value, { keys: ["email"] }),
                        filterAll: true
                      },
                      {
                        Header: "Nascita",
                        accessor: "birthDate",
                        id: 'birthDate',
                        Cell: (row) => {
                          let birthDate = moment(new Date(row.value * 1000)).format(
                            "DD/MM/YYYY"
                          );
                          return birthDate;
                        },
                        Filter: ({ filter, onChange }) => {

                          function clear() {
                            var a = document.getElementById("age_value");
                            a.value = '';
                          }

                          return (
                            <div >
                              <select
                                id='age_filter'
                                style={{ width: '60% ', fontSize: '12px' }}
                                onChange={event => {
                                  onChange(event.target.value);
                                  clear();
                                }
                                }
                              >
                                <option value="-">-</option>
                                <option value="over">Maggiore di:</option>
                                <option value="under">Minore di:</option>
                                <option value="equal">Uguale a:</option>
                              </select>
                              <Input
                                id='age_value'
                                style={{ width: '35%', fontSize: '12px' }}
                                name="age"
                                type="number"
                                className="react-select primary"
                                classNamePrefix="react-select"
                                onChange={event => onChange(event.target.value)}
                                placeholder="..anni"

                              />
                            </div>
                          );
                        },
                        filterMethod: (filter, row) => {
                          var selection = document.getElementById("age_filter");
                          //if (filter.value == '') return ;
                          if (selection.value == '-') return row[filter.id];
                          if (selection.value == 'equal') {
                            var today = new Date();
                            var birthDate = new Date(row._original.birthDate * 1000);
                            var candidate_age = today.getFullYear() - birthDate.getFullYear();
                            var m = today.getMonth() - birthDate.getMonth();
                            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                              candidate_age--;
                            }
                            if (candidate_age == filter.value) {
                              //console.log("trovato")
                              return row[filter.id]
                            }
                          }
                          if (selection.value == 'over') {
                            var today = new Date();
                            var birthDate = new Date(row._original.birthDate * 1000);
                            var candidate_age = today.getFullYear() - birthDate.getFullYear();
                            var m = today.getMonth() - birthDate.getMonth();
                            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                              candidate_age--;
                            }
                            if (candidate_age > filter.value) {
                              //console.log("trovato")
                              return row[filter.id]
                            }
                          }
                          if (selection.value == 'under') {
                            var today = new Date();
                            var birthDate = new Date(row._original.birthDate * 1000);
                            var candidate_age = today.getFullYear() - birthDate.getFullYear();
                            var m = today.getMonth() - birthDate.getMonth();
                            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                              candidate_age--;
                            }
                            if (candidate_age < filter.value) {
                              //console.log("trovato")
                              return row[filter.id]
                            }
                          }
                        },
                      },
                      {
                        Header: "Indirizzo", accessor: "residentialAddress", id: 'residentialAddress',
                        filterMethod: (filter, row) =>
                          matchSorter(row, filter.value, { keys: ["residentialAddress"] }),
                        filterAll: true
                      },
                      { Header: "Cellulare", accessor: "mobileNumber" },
                      {
                        Header: "Skills",
                        accessor: "skills",
                        Cell: (row) => {
                          return (
                            <div>
                              {row.original.skills?.map(
                                (skill) => skill.description + ", "
                              )}
                            </div>
                          );
                        },
                        filterMethod: (filter, row) => {
                          const filteredSkill = matchSorter(row, filter.value, { keys: [item => item._original.skills.map(skill => skill.description)] });
                          return filteredSkill
                        },
                        filterAll: true
                      },
                      {
                        Header: "Certificazioni",
                        accessor: "ceertifications",
                        Cell: (row) => {
                          return (
                            <div>
                              {row.original.certifications?.map(
                                (certification) =>
                                  certification.description + ", "
                              )}
                            </div>
                          );
                        },
                        filterMethod: (filter, row) => {
                          const filteredCertification = matchSorter(row, filter.value, { keys: [item => item._original.certifications.map(certification => certification.description)] });
                          return filteredCertification
                        },
                        filterAll: true
                      },
                      {
                        Header: "Azioni",
                        Cell: this.actions,
                        sortable: false,
                        filterable: false,
                        show: !this.props.readOnly,
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    nextText={"Avanti"}
                    previousText={"Indietro"}
                    pageText={"Pagina"}
                    ofText={"di"}
                    rowsText={"elementi"}
                    noDataText={"Nessun dato"}
                    loadingText={"Caricamento in corso"}
                    loading={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const data = {
    data: get(state, "candidate.data", []),
    loading: get(state, "candidate.loading", false),
  };

  if (data.data === null) data.data = [];

  return data;
};

export default connect(mapStateToProps, () => ({}))(candidateComponent);
