import {useState} from "react";

export const CheckBoxInputHook = (initialValue) => {
    const [value, setValue] = useState(initialValue);
    const clear = () => setValue(undefined);
    const getValue = () => value;

    return {
        clear,
        getValue,
        setValue,
        input: {
            checked: value,
            type: "checkbox",
            onChange: (e) => {
                setValue(e.target.checked);
            }
        }
    };
};
