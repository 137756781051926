import React, {useState} from 'react';
import {
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Col,
    Row,
    Container,
    Form, Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";

// For auto registry purpose
import './redux/login.reducer';

import {TextInputHook} from "../../hooks/text_input.hook";
import GraphqlClient from "../../client/graphql.client";
import store from "../../store/store";
import {userLogged} from "./redux/login.actions";


export default function Login(props) {

    const username = TextInputHook('');
    const password = TextInputHook('');
    const [loading, setLoading] = useState(false);

    const loginFunc = async (e) => {
        if (e) e.preventDefault();

        try {
            setLoading(true);

            let res = await GraphqlClient.basicLogin({username: username.getValue(), password: password.getValue()});
            
            const utente = {
                email: "",
                id: "",
                jsonextra: {
                    cognome: res.basicLogin.utente.cognome,
                    matricola: "",
                    nome: res.basicLogin.utente.nome,
                },
                jwt: res.basicLogin.accessToken,
                role: res.basicLogin.utente.ruolo,
                username: username.getValue()
            }
            store.dispatch(userLogged(utente)); // dispatch to redux

       /*     // -- LOGIN MOCK
          const userMock = {
            email: "mario@rossi.it",
            id: "36b388b3-60d8-4e8e-a5ca-edfd2a061094",
            jsonextra: {
              cognome: "Rossi",
              matricola: "",
              nome: "Mario",
            },
            jwt: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhbGciOiJIUzI1NiIsImV4cCI6MTYwMDEwNzA1NSwiaWF0IjoxNTk5ODQ3ODU1LCJpZCI6IjM2YjM4OGIzLTYwZDgtNGU4ZS1hNWNhLWVkZmQyYTA2MTA5NCIsIm5iZiI6MTU5OTg0Nzg1NSwidHlwIjoiSldUIiwidXNlciI6eyJpZCI6IjM2YjM4OGIzLTYwZDgtNGU4ZS1hNWNhLWVkZmQyYTA2MTA5NCIsInVzZXJuYW1lIjoia2xpdG9uLmJhcmUiLCJwYXNzIjoiZTkyNGI4NmFjOWY0MjFiMDU1NDJhMTY3YTQyZDc2MDY4MmZlMzhjOSIsImVtYWlsIjoiYmFyZWtsaXRvbkBnbWFpbC5jb20iLCJyb2xlIjoiYW1taW5pc3RyYXRvcmUiLCJzYWx0IjoiMWNkMzBjMTAtZDRkMi00MjA3LTg3ZmItOWUyYzA4NjRjMmE5IiwibGFzdFVwZGF0ZSI6MTU5ODk4NDc3OSwianNvbmV4dHJhIjoie1wiYWxsb3dlZENvbnRyYWN0c1wiOltdLFwibm9tZVwiOlwiS2xpdG9uXCIsXCJjb2dub21lXCI6XCJCYXJlXCIsXCJzZWN0b3JzUm9sZXNcIjpbXSxcIm1hdHJpY29sYVwiOlwiXCIsXCJzdXBlcmlvcmlcIjpbXSxcIm5vdGlmaWNhXCI6W10sXCJ0ZWxlZm9ub1wiOlwiXCJ9IiwiY29vcElEIjoiNzVhMDMxYWEtODk4MS00ZDFiLWFmMDItYzk5MWJhN2UyMDk3IiwiY29vcE5hbWUiOiJTLkMuUi4ifX0.uL3jR6hQFqhAS58sCrVawawaMRKD1aTKZcWSba0tUgs",
            role: "amministratore",
            username: "mario.rossi"
          };

            const res = {auth: userMock}; // questa parte del mock serve solo a ricostruire la risposta del graphqclient

            store.dispatch(userLogged(userMock)); // dispatch to redux
            // --- FINE LOGIN MOCK
        */
            /* Se si vuole fare un redirect particolare dopo il login in base al ruolo modificare il codice dello switch */
            switch (utente.role) {
                case "admin":
                    props.history.push("/admin/dashboard");
                    break;
                default:
                    props.history.push("/admin/s");
                    break
            }
        } catch (e) {
            return;
        } finally {
            setLoading(false)
        }
    };

    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" lg="4" md="6">
                        <h1 className="logoText">Omnia</h1>
                    </Col>
                </Row>
                <Col className="ml-auto mr-auto" lg="4" md="6">
                    <Form className="form" onSubmit={(e) => {
                        e.preventDefault();
                        loginFunc();
                    }}>
                        <button style={{display: 'none'}} type="submit"/>

                        <Card className="card-login card-white">
                            <CardHeader style={{height: "200px"}}>
                                <img
                                    alt="..."
                                    src={require("../../assets/img/card-primary.png")}
                                />
                            </CardHeader>
                            <CardBody>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="tim-icons icon-email-85"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input {...username.input} placeholder="Email" type="text"/>
                                </InputGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="tim-icons icon-lock-circle"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Password" type="password" {...password.input} />
                                </InputGroup>
                            </CardBody>
                            <CardFooter>
                                <Button
                                    block
                                    className="mb-3"
                                    color="primary"
                                    href="#pablo"
                                    onClick={loginFunc}
                                    active={!loading}
                                    size="lg"
                                >
                                    {
                                        loading ?
                                            <span>
                             <i className="fa fa-spin fa-spinner"/> Attendi...
                    </span>
                                            : "Login"
                                    }
                                </Button>
                                <div className="pull-right">
                                    <h6>
                                        <a className="link footer-link"
                                           href="#passwordDimenticata"
                                           onClick={e => e.preventDefault()}>
                                            Password dimenticata
                                        </a>
                                    </h6>
                                </div>
                            </CardFooter>
                        </Card>
                    </Form>
                </Col>
            </Container>
        </div>
    );
}

