import React, { Component } from "react";
/*DO NOT EDIT OR DELETE THIS LINE: YEOMAN ANCHOR IMPORT*/
import Candidate from "./pages/candidate/candidate.component";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/Login/login";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-chart-bar-32",
    component: Dashboard,
    layout: "/admin",
    /*roles: [RUOLO_AMMINISTRATORE]*/
  },
  {
    path: "/login",
    icon: "tim-icons icon-image-02",
    name: "Login",
    component: Login,
    layout: "/auth",
    visible: false,
  },
  /*DO NOT EDIT OR DELETE THIS LINE: YEOMAN ANCHOR ROUTES*/
  {
    path: "/candidate",
    icon: "tim-icons icon-image-02",
    name: "Candidate",
    component: Candidate,
    layout: "/admin",
  },
];

export default routes;
